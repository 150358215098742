<template>
  <div>

    <b-row>
      <b-col md="12">
        <b-row>
          <b-col md="2">
            <b-link class="text-decoration-none"  append>
            <b-card no-body :class="'text-center bg-primary height-card'">
              <b-row class="no-gutters h-100">
                <b-col md="8" class="text-light align-self-center text-center">
                  <span>Mesas. Disp.</span>
                </b-col>
                <b-col md="4" class="text-light align-self-center text-center">
                  <span class="align-middle h3">{{number_home.tables_actives}}</span>
                </b-col>
              </b-row>
            </b-card>
            </b-link>
          </b-col>
          <b-col md="2">
            <b-link class="text-decoration-none" @click="modalReservation" append>
            <b-card no-body :class="'text-center ' + (number_home.reservations > 0 ? 'bg-warning':'bg-primary')+ ' height-card'">
              <b-row class="no-gutters h-100">
                <b-col md="8" class="text-light align-self-center text-center">
                  <span>Reservas</span>
                </b-col>
                <b-col md="4" class="text-light align-self-center text-center">
                  <span class="align-middle h3">{{number_home.reservations}}</span>
                </b-col>
              </b-row>
            </b-card>
            </b-link>
          </b-col>
          <b-col md="2">
            <b-link class="text-decoration-none" @click="modalObserverVouchers" append>
            <b-card no-body :class="'text-center ' + (number_home.voucher_observer > 0 ? 'bg-danger':'bg-primary')+ ' height-card'">
              <b-row class="no-gutters h-100">
                <b-col md="8" class="text-light align-self-center text-center">
                  <span>Comp. Obs.</span>
                </b-col>
                <b-col md="4" class="text-light align-self-center text-center">
                  <span class="align-middle h3">{{number_home.voucher_observer}}</span>
                </b-col>
              </b-row>
            </b-card>
            </b-link>
          </b-col>
      
        </b-row>
      </b-col>


      <b-col md="10"></b-col>
      <b-col md="2">
        <b-row>
                <b-col md="12" class="mb-1">
                  <div class="w-100 text-center">Accesos Directos</div>
                </b-col>
                <b-col md="12">
                  <b-link class="text-decoration-none"  :to="{ path: '/pedido/mesas' }" append>
                  <CWidgetIcon header="Nuevo" text="Pedido" color="gradient-primary">
                    <img src="@/assets/icons/ventas.png" class="" />
                  </CWidgetIcon>
                  </b-link>
                </b-col>
                <b-col md="12">
                  <b-link class="text-decoration-none" :disabled="!Permission('BoxAdd')" :to="{ path: '/caja/nuevo' }" append>
                  <CWidgetIcon header="Nueva" text="Caja" color="gradient-success">
                    <img src="@/assets/icons/caja.png" class="" />
                  </CWidgetIcon>
                  </b-link>
                </b-col>
              </b-row>
      </b-col>
        
    </b-row>

    <ModalReservation />
    <ModalObserverVouchers />
  </div>
</template>
<style scoped>
  .height-card {
    height: 4.5rem;
  }
</style>
<script>

const axios = require("axios").default;
const je = require("json-encrypt");
import { mapState } from "vuex";

import EventBus from '@/assets/js/EventBus';
import ModalReservation from './components/ModalReservation'
import ModalObserverVouchers from './components/ModalObserverVouchers'


export default {
  name: 'Dashboard',

  components: {
   ModalReservation,
   ModalObserverVouchers,
  },
  data () {
    return {
      number_home :{
        reservations:0,
        voucher_observer:0,
        tables_actives:0,
      },
      name :'',


      service_expiration : [],
      modal_expiration: false,
    }
  },
   mounted () {
    EventBus.$on('RefreshNumberHome', () => {
      this.NumberHome();
    });
    this.NumberHome();
  },
  methods: {
    modalReservation,
    modalObserverVouchers,
    Permission,
    NumberHome,
  },
  computed: {
    ...mapState(["url_base"]),
    token: function () {
      let user = window.localStorage.getItem("user");
      user = JSON.parse(JSON.parse(je.decrypt(user)));
      return user.api_token;
    },
    id_establishment: function () {
      let establishment = window.localStorage.getItem("id_establishment");
      establishment = JSON.parse(je.decrypt(establishment));
      return establishment;
    }
  },
}


function modalReservation() {
  EventBus.$emit('ModalReservationShow');
}
function modalObserverVouchers() {
  EventBus.$emit('ModalObserverVouchersShow');
}


function NumberHome() {
  let me = this;
  let url = this.url_base + "home/total-home";
  axios({
    method: "GET",
    url: url,
  })
  .then(function (response) {
      if (response.data.status == 200) {
        me.number_home.reservations = parseFloat(response.data.reservations);
        me.number_home.voucher_observer = parseFloat(response.data.voucher_observer);
        me.number_home.tables_actives = parseFloat(response.data.tables_actives);
        
        // me.number_home.products = parseFloat(response.data.products);
        // me.number_home.clients = parseFloat(response.data.clients);
        // me.number_home.requirements = parseFloat(response.data.requirements);
        // me.number_home.transfers = parseFloat(response.data.transfers);
        // me.number_home.orders = parseFloat(response.data.orders);
        // me.modal_expiration = response.data.expiration;
        // me.service_expiration = response.data.service_expiration;
        
        // EventBus.$emit('DataChartSale',response.data.data_chart);
      }
  })
}

function Permission(module_permission) {
  let user_permissions = window.localStorage.getItem("user_permissions");
  user_permissions = JSON.parse(JSON.parse(je.decrypt(user_permissions)));
  if (user_permissions.indexOf(module_permission) > -1) {
    return true;
  } else {
    return false;
  }
}

</script>
